import Vue from 'vue';

export default {
  data() {
    return {
      searchFormState: null,
      jobsSearch: null,
      jobsCount: null,
      isAccordionActive: [],
      isLicenseModalShow: false,
      isPrefectureModalShow: false,
      isConditionModalShow: false,
      isConditionMenuShow: false,
      prevHash: '',
      scrollPix: null,
    };
  },
  created() {
    this.initJobsSearch();
    this.initJobsCount();
    this.parseHashForSearchForm();
    window.addEventListener('hashchange', this.parseHashForSearchForm);
  },
  mounted() {
    const vueInst = this;
    vueInst.processOverflow();
    window.addEventListener('hashchange', this.processOverflow);
    const groups = new Set($('input[data-group]').map(function getGroup() { return (this.dataset.group); }));
    groups.forEach((i) => {
      vueInst.parseIndet(i);
    });
  },
  computed: {
    prefectureJobsCount() {
      let sum = 0;
      if (this.jobsSearch.prefecture_names.length === 0) {
        Object.keys(this.jobsCount).forEach((key) => {
          sum += this.jobsCount[key];
        });
        return sum;
      }

      this.jobsSearch.prefecture_names.forEach((elem) => {
        let count = 0;

        if (this.jobsCount[elem]) {
          count = this.jobsCount[elem];
        }
        sum += count;
      });

      return sum;
    },
  },
  methods: {
    initJobsSearch() {
      const el = document.getElementById('new_jobs_search');
      const data = el ? JSON.parse(el.dataset.jobsSearch) : null;
      if (data) {
        data.annual_salary_min = data.annual_salary_min || '';
        data.annual_salary_max = data.annual_salary_max || '';
      }
      this.jobsSearch = data;
    },
    initJobsCount() {
      const el = document.getElementById('new_jobs_search');
      this.jobsCount = el ? JSON.parse(el.dataset.jobsCount) : null;
    },
    toggleMenu() {
      const geturl = window.location.href;
      const hash = geturl.split('#');
      if (!hash[1]) {
        this.setHash('search_by_menu');
      } else {
        this.setHash('');
      }
    },
    toggleMenuSearch() {
      this.scrollPix = $(window).scrollTop();
      this.setHash('search_by_menu');
    },
    toggleConditionForm() {
      this.isConditionMenuShow = !this.isConditionMenuShow;
      const conditionsForm = this.$refs['js-conditions-form'];
      const conditionsPanel = this.$refs['js-conditions-panel'];
      if (conditionsForm && conditionsPanel) {
        const conditionsFormHeight = conditionsPanel.getBoundingClientRect().height; // 要素の縦幅
        conditionsForm.style.height = this.isConditionMenuShow ? `${conditionsFormHeight}px` : '0';
      }
    },
    goBack() {
      this.setHash(this.prevHash);
    },
    processOverflow() {
      const nodeTds = document.querySelectorAll('#search_menu td');
      const tds = Array.prototype.slice.call(nodeTds, 0);
      tds.forEach((td) => {
        if (td.scrollWidth > td.offsetWidth) {
          const tr = td.parentElement;
          tr.insertBefore(td, tr.lastChild.nextSibling);
          /* eslint-disable no-param-reassign */
          td.style.width = '100%';
        }
      });
      const geturl = window.location.href;
      const hash = geturl.split('#');
      if (!hash[1] && this.scrollPix) {
        window.scrollTo(0, this.scrollPix);
        this.scrollPix = null;
      }
    },
    parseHashForSearchForm() {
      const hashKey = window.location.hash.replace('#', '');
      switch (hashKey) {
      case 'search_by_license':
      case 'search_by_prefecture':
      case 'search_by_category':
      case 'search_by_type':
      case 'search_by_menu':
      case 'search_by_other':
        this.searchFormState = hashKey;
        break;
      default:
        this.searchFormState = null;
      }
    },
    clickPrefectureButton(value) {
      this.jobsSearch.prefecture_names.push(value);
      Vue.nextTick(() => {
        $('#search_menu')[0].submit();
      });
    },
    setHash(hashKey) {
      this.prevHash = window.location.hash.slice(1);
      window.location.hash = hashKey;
      $('body, html').scrollTop(0);
    },
    reselectLocation() {
      if (!this.jobsSearch.prefecture_name) {
        this.jobsSearch.area_name = null;
      }
      this.jobsSearch.prefecture_name = null;
      this.jobsSearch.city_names = [];
    },
    toggle(index) {
      this.isAccordionActive[index] = this.isAccordionActive[index];
      this.isAccordionActive.splice(index, 1, !this.isAccordionActive[index]);
    },
    openPrefectureModal() {
      this.isPrefectureModalShow = true;
    },
    closePrefectureModal() {
      this.isPrefectureModalShow = false;
    },
    openConditionModal() {
      this.isConditionModalShow = true;
    },
    closeConditionModal() {
      this.isConditionModalShow = false;
    },
    selectAllPrefecture(areaName) {
      const areaNameChecked = document.querySelector(`input[value="${areaName}"]`).checked;
      if (areaNameChecked) {
        document.querySelectorAll(`input[data-area-name="${areaName}"]`).forEach((elem) => {
          this.jobsSearch.prefecture_names.push(elem.value);
        });
      } else {
        document.querySelectorAll(`input[data-area-name="${areaName}"]`).forEach((elem) => {
          this.jobsSearch.prefecture_names.pop(elem.value);
        });
      }

      this.jobsSearch.prefecture_names = this.jobsSearch.prefecture_names.filter((x, i, self) => self.indexOf(x) === i);
    },
    deselectArea(prefectureName) {
      const prefectureNameInput = document.querySelector(`input[value="${prefectureName}"]`);
      if (!prefectureNameInput.checked) {
        document.querySelector(`input[value="${prefectureNameInput.dataset.areaName}"]`).checked = false;
      }
    },
    selectAllCategories() {
      const categoryNameChecked = document.querySelector('input[name="select_all[category_names]"]').checked;
      if (categoryNameChecked) {
        document.getElementsByName('jobs_search[category_names][]')
          .forEach((elem) => {
            this.jobsSearch.category_names.push(elem.value);
          });
      } else {
        document.getElementsByName('jobs_search[category_names][]')
          .forEach((elem) => {
            this.jobsSearch.category_names.pop(elem.value);
          });
      }
    },
    deselectCategories() {
      document.querySelector('input[name="select_all[category_names]"]').checked = false;
    },
    selectAllTypes() {
      const typeNameChecked = document.querySelector('input[name="select_all[type_names]"]').checked;
      if (typeNameChecked) {
        document.getElementsByName('jobs_search[type_names][]')
          .forEach((elem) => {
            this.jobsSearch.type_names.push(elem.value);
          });
      } else {
        document.getElementsByName('jobs_search[type_names][]')
          .forEach((elem) => {
            this.jobsSearch.type_names.pop(elem.value);
          });
      }
    },
    deselectTypes() {
      document.querySelector('input[name="select_all[type_names]"]').checked = false;
    },
    selectAllLicenses(majorLicenseName) {
      Vue.nextTick(() => {
        const majorLicenseNameChecked = this.jobsSearch.license_names.includes(majorLicenseName);

        if (majorLicenseNameChecked) {
          document.querySelectorAll(`input[data-major-license-name="${majorLicenseName}"]`)
            .forEach((elem) => {
              this.jobsSearch.license_names.push(elem.value);
            });
        } else {
          document.querySelectorAll(`input[data-major-license-name="${majorLicenseName}"]`)
            .forEach((elem) => {
              const index = this.jobsSearch.license_names.indexOf(elem.value);
              this.jobsSearch.license_names.splice(index, 1);
            });
        }

        this.jobsSearch.license_names = this.jobsSearch.license_names.filter((x, i, self) => self.indexOf(x) === i);
      });
    },
    selectLicense(licenseName) {
      Vue.nextTick(() => {
        const licenseNameInput = document.querySelector(`input[value="${licenseName}"]`);
        this.checkAllLicense(licenseNameInput.dataset.majorLicenseName);
      });
    },
    checkAllLicense(majorLicenseName) {
      const array = [];

      document.querySelectorAll(`input[data-major-license-name="${majorLicenseName}"]`)
        .forEach((elem) => {
          array.push(this.jobsSearch.license_names.includes(elem.value));
        });

      if (array.every(val => val === true)) {
        this.jobsSearch.license_names.push(majorLicenseName);
      } else if (this.jobsSearch.license_names.includes(majorLicenseName)) {
        const index = this.jobsSearch.license_names.indexOf(majorLicenseName);
        this.jobsSearch.license_names.splice(index, 1);
      }
    },
    resetForm() {
      this.jobsSearch.prefecture_names = [];
      this.jobsSearch.license_names = [];
      this.jobsSearch.category_names = [];
      this.jobsSearch.type_names = [];
      this.jobsSearch.contract_names = [];
      this.jobsSearch.feature_names = [];
      this.jobsSearch.area_names = [];
      $('html, body').scrollTop($('#search_menu').offset());
    },
    selectAll(e, destination) {
      const vueInst = this;
      const pushTarget = vueInst.jobsSearch[destination];
      const el = e.target;
      const cbGroup = $(`input[data-group=${el.value}]`);
      if (el.checked) {
        cbGroup.each(function processGroupWhenChecked() {
          if (!pushTarget.includes(this.value)) { pushTarget.push(this.value); }
        });
      } else {
        cbGroup.each(function processGroupWhenNotChecked() {
          vueInst.removeThis(pushTarget, this.value);
        });
      }
    },
    getKey(el) {
      const x1 = el.name.indexOf('[');
      const x2 = el.name.indexOf(']');
      return el.name.substring(x1 + 1, x2);
    },
    removeThis(arr, value) {
      const j = arr.indexOf(value);
      if (j > -1) {
        arr.splice(j, 1);
      }
    },
    parseIndet(group) {
      const parentBox = $(`.parent-cb input[value=${group}]`);
      if (!parentBox[0]) { return; }

      const key = this.getKey(parentBox[0]);
      let someChecked = $(`input[data-group=${group}]:checked`);
      someChecked = someChecked.map(function getValues() { return this.value; });
      someChecked = new Set(someChecked).size;
      const allChecks = parseInt(parentBox[0].dataset.count, 10);
      const indetState = (someChecked >= 0 && someChecked < allChecks);
      const allChecked = (someChecked === allChecks);
      parentBox.each(function checkAll() {
        this.checked = allChecked;
      });
      if (indetState) {
        this.removeThis(this.jobsSearch[key], parentBox[0].value);
      } else if (allChecked) {
        this.jobsSearch[key].push(parentBox[0].value);
      }
    },
    deselectThis(e) {
      this.$forceUpdate();
      Vue.nextTick(() => {
        const el = e.target;
        this.parseIndet(el.dataset.group);
      });
    },
  },
};
